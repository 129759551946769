<template>
  <div class="table">
    <el-table
      :data="tableData"
      stripe
      header-row-class-name="btn"
      @selection-change="handleSelectionChange"
      style="width: 100%; border: none"
      empty-text="暂无数据"
      max-height="500"
    >
      <!-- 多选框 -->
      <el-table-column type="selection" width="55" v-if="showCheck" />
      <template v-for="(v, index) in columns" :key="index">
        <el-table-column
          style="border: none"
          :min-width="v.width"
          :label="v.label"
          :show-overflow-tooltip="!v.type || v.type === 'text'"
          v-if="v.show || (v.type == 'button' && !v.btns.length)"
          :fixed="v.fixed"
        >
          <template #default="scope">
            <!-- 文字 -->
            <div v-if="v.type === 'text'" :style="'color:' + v.color">
              <span>{{ scope["row"][v.prop] }}</span>
            </div>
            <!-- value转换 label + 变颜色-->
            <div v-if="v.type === 'option'">
              <span :style="'color:' + v.colorData[scope['row'][v.prop]]">{{
                v.metaData[scope["row"][v.prop]]
              }}</span>
            </div>
            <!-- <输入框 -->
            <div v-if="v.type === 'input'" :style="'color:' + v.color">
              {{ scope["row"][v.prop] }}
            </div>
            <!-- 查看 -->
            <div v-if="v.type === 'view'" :style="'color:' + v.color">
              <div :style="'color:' + scope['row'].color">
                <span
                  class="border-icon"
                  :class="
                    scope['row'][v.prop]
                      ? 'border-icon-active'
                      : 'border-icon-disabled'
                  "
                  @click="handleView(scope['row'][v.prop], v.label)"
                >
                  <i class="iconfont icon-a-zu4754"></i>
                </span>
              </div>
            </div>
            <!-- 二维码查看 -->
            <div v-if="v.type === 'codeView'" :style="'color:' + v.color">
              <div :style="'color:' + scope['row'].color">
                <span
                  class="border-icon"
                  :class="
                    scope['row'][v.prop]
                      ? 'border-icon-active'
                      : 'border-icon-disabled'
                  "
                  @click="handleCodeView(scope['row'][v.prop])"
                >
                  <i class="iconfont icon-a-zu4536"></i>
                </span>
              </div>
            </div>
            <!-- 视频-->
            <div v-if="v.type === 'video'" :style="'color:' + v.color">
              <div :style="'color:' + scope['row'].color">
                <span
                  class="border-icon"
                  :class="
                    scope['row'][v.prop]
                      ? 'border-icon-active'
                      : 'border-icon-disabled'
                  "
                  @click="playVideo(scope['row'][v.prop])"
                >
                  <i class="iconfont icon-a-zu4754"></i>
                </span>
              </div>
            </div>
            <!-- 下载 -->
            <div v-else-if="v.type === 'download'" :style="'color:' + v.color">
              <span class="border-icon download-icon">
                <i
                  class="iconfont icon-a-zu4755"
                  @click="handleDownload(scope['row'][v.prop])"
                ></i>
              </span>
            </div>
            <!-- 图片 -->
            <template v-if="v.type === 'image'">
              <div v-if="scope['row'][v.prop]" class="img">
                <el-image
                  v-for="(item, i) in scope['row'][v.prop]"
                  :key="i"
                  style="width: 30px; height: 30px; margin-left: 2px"
                  :src="item"
                  v-show="i < 3"
                  :preview-src-list="scope['row'][v.prop]"
                  :initial-index="0"
                >
                </el-image>
              </div>
              <div v-else>- -</div>
            </template>
            <!-- 开关 -->
            <div v-else-if="v.type === 'switch'">
              <el-switch
                v-model="scope['row'][v.prop]"
                @click="clickBtn(v.action, scope)"
                inline-prompt
                active-text="启用"
                inactive-text="关闭"
              >
              </el-switch>
            </div>
            <!-- 按钮 -->
            <div v-else-if="v.type === 'button'">
              <template v-for="(t, i) in v.btns" :key="i">
                <template v-if="t.disabledStatus">
                  <el-button
                    size="small"
                    plain
                    round
                    v-if="scope.row[t.disabledStatus]"
                    class="margin-left theme-btn"
                    @click="clickBtn(t.action, scope, t)"
                    :class="t.class"
                    >{{ t.name }}</el-button
                  >
                </template>
                <el-button
                  v-else
                  size="small"
                  plain
                  round
                  class="margin-left theme-btn"
                  @click="clickBtn(t.action, scope, t)"
                  :class="t.class"
                  >{{ t.name }}</el-button
                >
              </template>
            </div>

            <!-- 特殊处理 插槽 -->
            <slot
              v-else-if="v.type === 'customRender'"
              :name="v.prop"
              :scope="scope"
              :row="scope.row"
            ></slot>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <slot></slot>
    <div class="pages" v-if="pagination.showPagin">
      <!-- v-if="parseInt(pagination.total/pagination.pageSize) > 1" -->
      <el-pagination
        background
        :page-sizes="pagination.pageSizes"
        :layout="pagination.total != 0 ? pagination.layout : ''"
        :page-size="pagination.pageSize"
        :total="pagination.total"
        @current-change="currentChange"
        @size-change="sizeChange"
        :hide-on-single-page="pagination.hidePage"
      >
      </el-pagination>

      <div class="btn-action">
        <template v-if="btns">
          <el-button
            v-for="(item, index) in btns"
            :key="index"
            @click="clickBtn(item.action, 'e')"
            size="small"
            round
            class="text-theme-color border-theme-color"
          >
            <i class="iconfont" :class="item.icon ? item.icon : ''"></i>
            {{ item.lable }}
          </el-button>
        </template>
      </div>
    </div>

    <Wdialog ref="videoDialog" title="视频播放" :showAction="false">
      <div class="flex-center">
        <video
          height="400"
          width="500"
          :src="videoSrc"
          controls="controls"
        ></video>
      </div>
    </Wdialog>
    <Wdialog ref="viewDialog" :title="viewTitle" :showAction="false">
      <div v-html="viewHtml"></div>
    </Wdialog>
    <Wdialog
      ref="codeViewDialog"
      title="二维码"
      width="30%"
      :showAction="false"
      @wCancel="handleResetQrcode"
    >
      <div class="flex-center" ref="codeView"></div>
    </Wdialog>
  </div>
</template>

<script>
import { ref, nextTick } from "vue";
import Wdialog from "../wDialog/wDialog.vue";
import QRCode from "qrcodejs2";
export default {
  /**
   * pagination  分页数据
   * total  总页数
   * pageSize 每页多少条
   * pageSizes 每页条数
   * showPagin 是否显示分页
   * columns  表格参数配置
   * tableData 表格数据
   * showCheck  是否显示多选
   *
   * btns 右下角按钮
   * */
  props: ["tableData", "columns", "pagination", "showCheck", "btns"],
  components: {
    Wdialog,
  },
  setup(props, { emit }) {
    /**
     * 抛出按钮事件
     *
     * */
    const clickBtn = (action, scope, t) => {
      emit(action, scope, t?.name);
    };
    const tableBase = ref({
      showSummary: true, //是否在表尾显示合计行
      headerRowClassName: true,
    });
    /**
     * 分页
     * currentChange
     * */
    const currentChange = (page) => {
      emit("currentChange", page);
    };

    /**
     * 每页条数
     */
    const sizeChange = (page) => {
      emit("sizeChange", page);
    };
    /**
     * 多选
     * */
    const handleSelectionChange = (change) => {
      emit("handleSelectionChange", change);
    };

    /**
     * 播放视频
     * */
    const videoDialog = ref(null);
    const videoSrc = ref("");
    async function playVideo(src) {
      videoSrc.value = await src;
      src && videoDialog.value.show();
    }
    function handleDownload(url) {
      const name = Math.random().toString(10).substr(2, 4);
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        let a = document.createElement("a");
        const file = new File([xhr.response], `${name}.jpeg`, {
          type: xhr.response.type,
        });
        const dataUrl = window.URL.createObjectURL(file);
        const event = new MouseEvent("click");
        a.download = file.name;
        a.href = dataUrl;
        a.dispatchEvent(event);
        window.URL.revokeObjectURL(dataUrl);
        a = null;
      };
      xhr.send();
    }
    /**
     * 查看富文本
     * */
    const viewHtml = ref(null);
    const viewDialog = ref(null);
    const viewTitle = ref("详情");

    function handleView(row, title) {
      viewHtml.value = row;
      viewTitle.value = title;
      viewDialog.value.show();
    }
    /**
     * 查看二维码
     * */
    const codeView = ref(null);
    const codeViewDialog = ref(null);
    const qrcode = ref(null);
    function handleCodeView(row) {
      codeViewDialog.value.show();
      nextTick(() => {
        codeView.value.innerHTML = "";
        qrcode.value = new QRCode(codeView.value, {
          text: row,
          width: 250,
          height: 250,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
      });
    }
    function handleResetQrcode() {
      nextTick(() => {
        qrcode.value.clear();
      });
    }
    return {
      playVideo,
      videoDialog,
      handleSelectionChange,
      sizeChange,
      currentChange,
      clickBtn,
      tableBase,
      videoSrc,
      handleDownload,
      handleView,
      viewDialog,
      viewHtml,
      viewTitle,
      handleCodeView,
      codeView,
      codeViewDialog,
      handleResetQrcode,
    };
  },
};
</script>

<style lang="scss">
.check-col {
  height: 26px;
}
.table {
  padding: 10px;
  .el-checkbox {
    height: auto;
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    > .el-image {
      width: 30px;
      height: 30px;
      display: block;
      object-fit: cover;
      margin: 0 8px 0 0;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .border-icon {
    i {
      font-size: 20px;
    }
  }
  .download-icon {
    background-color: #f2f2f2;
    color: #ccc;
  }
}

// 用于解决 table 行 显示不完整
.el-table__fixed,
.el-table__fixed-right {
  height: 100% !important;
}
.el-pagination.is-background .el-pager li,
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination__sizes .el-select .el-input__inner {
  background-color: #fff;
  border: 1px solid #d6e5fa;
  color: var(--theme-color);
  font-weight: normal;
}
.el-pagination.is-background .btn-next:disabled,
.el-pagination.is-background .btn-prev:disabled {
  border: 1px solid var(--search-uncheck-bg-color);
}
.el-pagination__sizes .el-select .el-input .el-select__caret {
  color: var(--theme-color);
}
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: var(--menu-bg-color);
  color: var(--theme-color);
  font-weight: bold;
}
.el-pagination__total {
  padding-left: 10px;
  color: var(--theme-color);
}
.theme-btn {
  background-color: var(--search-bg-color);
  color: var(--theme-color);
  border-color: var(--search-bg-color);
}

.el-button:focus,
.el-button:hover {
  color: var(--text-white-color);
  border-color: var(--theme-color);
  background-color: var(--theme-color);
  outline: 0;
}

.el-switch {
  --el-switch-on-color: var(--theme-color);
}

.el-table th {
  background-color: var(--search-bg-color) !important;
  font-weight: bold;
  font-size: 16px;
  border: none;
}

.el-table td,
.el-table th {
  border-bottom: none !important;
  color: var(--text-color);
}

.el-table__body {
  .el-table__row .el-table__cell {
    padding: 8px 0;
  }
}

.pages {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
</style>
